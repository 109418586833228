<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false"
		:visible.sync="dialogFile" width="800px" v-loading="loadingUpload"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_c09a4ec2fe473b0a')" append-to-body :visible.sync="dialogFile"
		v-loading="loadingUpload" :direction="'rtl'" size="800px">

		<div>
			<el-form ref="form" :inline="true" :rules="formRules" :model="form" label-width="100px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<!-- <div> -->
				<!-- country: '',
						state:'',
						district: '',
						city: '',
						address1: '',
						address2: '',
						name: '',
						phone: '',
						email: '',
						postalCode: '', -->

				<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="">
					<el-input size="" clearable v-model="form.country"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_7cab79716236ebb0')+'/'+$t('i18nn_f9514183570a10fe')" prop="">
					<el-input size="" clearable v-model="form.state"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_e691da78ee2e02f5')+'/'+$t('i18nn_b7f95c7fddc0d602')" prop="">
					<el-input size="" clearable v-model="form.district"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="">
					<el-input size="" clearable v-model="form.city"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')+'1'" prop="">
					<el-input size="" clearable v-model="form.address1"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')+'2'" prop="">
					<el-input size="" clearable v-model="form.address2"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_ea4756bc1642e0f1')" prop="">
					<el-input size="" clearable v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_2d13b6f878f30be3')" prop="">
					<el-input size="" clearable v-model="form.phone"></el-input>
				</el-form-item>
				<el-form-item :label="'email'" prop="">
					<el-input size="" clearable v-model="form.email"></el-input>
				</el-form-item>
				<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="">
					<el-input size="" clearable v-model="form.postalCode"></el-input>
				</el-form-item>
				<!-- </div> -->
			</el-form>
			
			<div style="padding: 10px;">
				<!-- // amount
				// hsCode
				// name
				// quantity
				// sku -->
				<el-button type="success" size="small" icon="el-icon-plus" @click="addDet()">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
				<el-table border :data="skuList" size="small" :max-height="$store.state.frameConHeightWh6" style="width: 100%;"
					:empty-text="$t('i18nn_80755d56db88fca2')">
					
					<el-table-column type="index" width="50" align="center"
						:label="$t('Storage.tableColumn.no')"></el-table-column>
					<el-table-column prop="sku" label="SKU">
						<template slot-scope="scope">
							<el-input size="small" v-model="scope.row.sku" maxlength="">
							</el-input>
						</template>
					</el-table-column>
					<el-table-column prop="quantity" :label="$t('Storage.DropShipping.quantity')">
						<template slot-scope="scope">
							<el-input-number size="small" v-model="scope.row.quantity"  controls-position="right">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="amount" :label="$t('i18nn_6867f16a2ac5e825')">
						<template slot-scope="scope">
							<el-input-number size="small" v-model="scope.row.amount"  controls-position="right">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="hsCode" :label="'hsCode'">
						<template slot-scope="scope">
							<el-input size="small" v-model="scope.row.hsCode" maxlength="">
							</el-input>
						</template>
					</el-table-column>
					
					<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
						<template slot-scope="scope">
							<div>
								<el-button @click="delDet($event, scope.row,scope.$index)" type="danger" size="small"
									icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			
		</div>


		<div class="drawer-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
			<el-button type="primary" icon="el-icon-tickets" @click="submitFormAction()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import {
		deepClone
	} from '@/utils/common.js';
	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}

		},
		components: {

		},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,

				loading: false,
				// amount
				// hsCode
				// name
				// quantity
				// sku
				skuList: [],
				form: {
					// id: '',
					country: '',
					state: '',
					district: '',
					city: '',
					address1: '',
					address2: '',
					name: '',
					phone: '',
					email: '',
					postalCode: '',
				},
				formRules: {
					sendOutType: [{
						required: true,
						message: this.$t('FormMsg.Please_select'),
						trigger: 'change'
					}]
				},
				selectOption: {
					wh_send_out_type: [],
					wh_car_tms_type: []
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {},
		//编译挂载前
		mounted() {},
		methods: {
			initData() {
				let receiver = this.row.receiver;
				this.form = {
					country: receiver.country,
					state: receiver.state,
					district: receiver.district,
					city: receiver.city,
					address1: receiver.address1,
					address2: receiver.address2,
					name: receiver.name,
					phone: receiver.phone,
					email: receiver.email,
					postalCode: receiver.postalCode,
				};
				this.skuList = deepClone(this.row.skuList);
				
			},
			submitFormAction() {
				this.submitExcelFormHTTP();
			},
			//提交-
			submitExcelFormHTTP() {
				let formData = {
					id: this.row.id,
					receiver: this.form,
					skuList: this.skuList
				};
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.WhThirdOrderReceiverEdit, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.dialogFile = false;
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							this.$emit('success');
							// }
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//新增
			addDet() {
				// event.stopPropagation();
				this.skuList.push({
					amount: null,
					hsCode: null,
					name: null,
					quantity: null,
					sku: null,
				});
			},
			// 删除
			delDet(event, row, index) {
				event.stopPropagation();
				this.skuList.splice(index, 1);
			},

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);

			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>