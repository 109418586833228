<template>
	<!--  -->
	<el-dialog append-to-body :close-on-click-modal="false" :visible.sync="dialogFile"
		width="1000px">
		<template slot="title">
			<div>EC易仓<span>{{$t('i18nn_766bfac938d98e04')}}</span></div>
		</template>
		<div class="" v-loading="loading">
			<el-card>
				<div>
					<span class="filter-name"><i class="red">*</i>{{$t('i18nn_3f9e9a6fe6f4f971')}}</span>
					<el-select filterable v-model="filterData.orderStatus" :placeholder="$t('FormMsg.Please_select')">
						<el-option v-for="item in selectOption.wh_ec_order_status" :key="item.code"
							:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select>
				</div>
			</el-card>
			<el-card>
				

				<div>
					<div style="margin-bottom: 5px;">
						<span class="filter-name"><span>{{$t('i18nn_c944a6686d996ab3')}}</span>(<span>{{$t('i18nn_31ce64e52ed71628')}}</span>)</span>
						<span>
							<!-- <el-checkbox-group v-model="selPullYiCangWhData">
								<el-checkbox v-for="(item,index) in pullYiCangWhData" :label="item.warehouse_id" :key="item.warehouse_id">
									{{item.warehouse_code}}/{{item.warehouse_desc}}
								</el-checkbox>
							</el-checkbox-group> -->
							
							<!-- <el-select filterable clearable v-model="selPullYiCangWhData" multiple :placeholder="$t('i18nn_2ad108ab2c560530')">
							    <el-option
							       v-for="(item,index) in pullYiCangWhData"
							      :key="item.warehouse_id"
							      :label="item.warehouse_code"
							      :value="item.warehouse_id">
							      <div class="sel_option_s1" style="">
							      	<span class="sel_option_name">{{ item.warehouse_code }}</span>
							      	<span class="sel_option_code" style="">{{ item.warehouse_desc }}</span>
							      </div>
							    </el-option>
							  </el-select> -->
								<whYiCangWhSelect @changeData="changYiCangWh" :openTime="openTimeYiCangWh" size="medium" :width="500"></whYiCangWhSelect>
						</span>
						
					</div>
					<div style="margin-bottom: 5px;">
						<span class="filter-name"><span>{{$t('i18nn_590686fdff40723b')}}</span>(<span>{{$t('i18nn_31ce64e52ed71628')}}</span>)</span>
						<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }" v-model="filterData.refNosStr"
							size="small" clearable show-word-limit maxlength="3000" :placeholder="$t('i18nn_380912455c6e2f06')"
							style="width: 700px;vertical-align: middle; " />
					</div>
					<div style="margin-bottom: 5px;">
						<span class="filter-name"><span>{{$t('i18nn_68a6337017ca7d67')}}</span>(<span>{{$t('i18nn_31ce64e52ed71628')}}</span>)</span>
						<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }"
							v-model="filterData.saleOrderCodeStr" size="small" clearable show-word-limit
							maxlength="3000" :placeholder="$t('i18nn_380912455c6e2f06')"
							style="width: 700px;vertical-align: middle; " />
					</div>
					<div style="margin-bottom: 5px;">
						<span class="filter-name"><span>{{$t('i18nn_a3b6f85b4a9530ff')}}</span>(<span>{{$t('i18nn_31ce64e52ed71628')}}</span>)</span>
						<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10 }"
							v-model="filterData.warehouseOrderCodeStr" size="small" clearable show-word-limit
							maxlength="3000" :placeholder="$t('i18nn_380912455c6e2f06')"
							style="width: 700px;vertical-align: middle; " />
					</div>

					<!-- <el-button type="primary" size="small" style="margin-bottom: 10px;" icon="el-icon-plus" @click="add()">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
					<el-table :data="submitData" stripe :border="true" size="small">
						<el-table-column prop="refNo" :label="'参考号(非必填)'">
							<template slot-scope="scope">
								<div><el-input size="small" clearable v-model="scope.row.refNo" placeholder="请输入(非必填)"></el-input></div>
							</template>
						</el-table-column>
						<el-table-column prop="saleOrderCode" :label="'销售单号(非必填)'">
							<template slot-scope="scope">
								<div><el-input size="small" clearable v-model="scope.row.saleOrderCode" placeholder="请输入(非必填)"></el-input></div>
							</template>
						</el-table-column>
						<el-table-column prop="warehouseOrderCode" :label="'仓库编码(非必填)'">
							<template slot-scope="scope">
								<div><el-input size="small" clearable v-model="scope.row.warehouseOrderCode" placeholder="请输入(非必填)"></el-input></div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right">
							<template slot-scope="scope">
								<div>
									<el-button @click="del($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table> -->
				</div>
			</el-card>
		</div>

		<div slot="footer" class="">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<el-button type="warning" @click="pullOrderAction()" icon="el-icon-refresh">{{$t('i18nn_a456e74e3c8a95f2')}}</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import { exportPdfByShippingMark } from '@/utils/PdfShippingMark.js'
	// import barcode from '@/components/Common/barcode.vue';
	import whYiCangWhSelect from '@/components/StorageCenter/components/whYiCangWhSelect.vue';
	
	export default {
		props: {
			openTime: {},
			row: {}
		},
		components: { whYiCangWhSelect },
		data() {
			return {
				dialogFile: false,

				// loading_sel: false,
				// pullYiCangWhData: [],
				selPullYiCangWhData: [],
				openTimeYiCangWh:"",
				// loadingUpload: false,
				loading: false,
				filterData: {
					orderStatus: '',
					refNosStr: '',
					saleOrderCodeStr: '',
					warehouseOrderCodeStr: '',
				},
				loading_load: false,
				// submitData: [],

				selectOption: {
					wh_ec_order_status: []
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_ec_order_status'],
				(data)=>{
					this.selectOption.wh_ec_order_status = data['wh_ec_order_status'];
			});
			// this.openTimeYiCangWh = new Date().getTime();
		},
		methods: {
			initData() {
				// this.filterData.id = this.row.id;
				// this.getPageData();
				// this.submitData = [];
				this.filterData.refNosStr = "";
				this.filterData.saleOrderCodeStr = "";
				this.filterData.warehouseOrderCodeStr = "";
				this.openTimeYiCangWh = new Date().getTime();
				// this.getPullYiCangWhQuery();
			},
			changYiCangWh(data) {
				console.log('changYiCangWh', data);
				this.selPullYiCangWhData = data.map((item)=>{
					return item.warehouse_id;
				});
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			//下载pdf
			// downLoadPdf(){
			// 	this.$nextTick(()=>{
			// 		exportPdfByShippingMark(this.priterData,"ShippingMark_"+this.row.packingNo);
			// 	});
			// },
			// 新建
			// add() {
			// 	this.submitData.push({
			// 		saleOrderCode: '', //
			// 		warehouseOrderCode: '' ,//
			// 		refNo:''
			// 	});
			// },
			// //删除
			// del(event, row, index) {
			// 	this.submitData.splice(index, 1);
			// },
			//易仓拉取查询-仓库
			// getPullYiCangWhQuery() {
			// 	// let _this = this;
			// 	this.loading_sel = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhThirdYiCangWhQuery, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('易仓拉取查询-仓库');
			// 			console.log(data);
			// 			
			// 			this.loading_sel = false;
			// 			this.pullYiCangWhData = data.rows;

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('分页，请求失败');
			// 			this.loading_sel = false;
			// 		});
			// },
			//拉取最新订单
			pullOrderAction() {
				// event.stopPropagation();
				let refNos = [];
				let saleOrderCodes = [];
				let warehouseOrderCodes = [];

				// this.submitData.forEach(item=>{
				// 	refNos.push(item.refNo);
				// 	saleOrderCodes.push(item.saleOrderCode);
				// 	warehouseOrderCodes.push(item.warehouseOrderCode);
				// });

				// let seqNumber = [];
				if (this.filterData.refNosStr) {
					refNos = this.filterData.refNosStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				if (this.filterData.saleOrderCodeStr) {
					saleOrderCodes = this.filterData.saleOrderCodeStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				if (this.filterData.warehouseOrderCodeStr) {
					warehouseOrderCodes = this.filterData.warehouseOrderCodeStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}

				let parm = {
					"proxyUserId": this.row && this.row.proxyUserId ? this.row.proxyUserId : null,
					"status": this.filterData.orderStatus,
					"refNos": refNos,
					"saleOrderCodes": saleOrderCodes,
					"warehouseOrderCodes": warehouseOrderCodes,
					"warehouseIds": this.selPullYiCangWhData
				};

				this.postData(this.$urlConfig.WhThirdOrderPullOrder, parm);
			},
			//请求分页数据
			// getPageData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhPrinterShippingMark + '/' + this.filterData.id, {})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.priterData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error('数据有误!');
			// 			this.loading_load = false;
			// 		});
			// },
			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {

						// this.getPageData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						this.$emit("actionSuccess");
						this.dialogFile = false;
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_ec_order_status'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {

			// 				// this.selectOption.wh_third_platform = data.data['wh_third_platform'];
			// 				this.selectOption.wh_ec_order_status = data.data['wh_ec_order_status'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.filter-name {
		display: inline-block;
		vertical-align: middle;
		width: 150px;
		text-align: right;
		padding: 0 10px;
	}
</style>
