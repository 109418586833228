<template>
	<!--  -->
	<el-dialog :title="$t('i18nn_e99e48ab45c5e800')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile"
		width="500px">

		<div class="" v-loading="loading">
			<el-card>
				<div>
					<span class="filter-name"><i class="red">*</i>{{$t('i18nn_c944a6686d996ab3')}}</span>
					<!-- <el-select filterable v-model="filterData.orderStatus" :placeholder="$t('FormMsg.Please_select')">
						<el-option v-for="item in selectOption.wh_ec_order_status" :key="item.code"
							:label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select> -->
					<whNoSelect @changeData="changWhNo"></whNoSelect>
				</div>
			</el-card>
			
		</div>

		<div slot="footer" class="">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<el-button type="primary" @click="submitAction()" icon="el-icon-refresh">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
		</div>
	</el-dialog>
</template>
<script>
	// import { exportPdfByShippingMark } from '@/utils/PdfShippingMark.js'
	// import barcode from '@/components/Common/barcode.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	
	export default {
		props: {
			openTime: {},
			row: {},
			ids:{},
		},
		components: { whNoSelect },
		data() {
			return {
				dialogFile: false,
				// loadingUpload: false,
				loading: false,
				filterData: {
					whNo:'',
					// orderStatus: '',
					// refNosStr:'',
					// saleOrderCodeStr:'',
					// warehouseOrderCodeStr:'',
				},
				loading_load: false,
				// submitData: [],

				// selectOption: {
				// 	wh_ec_order_status: []
				// },
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				// this.filterData.id = this.row.id;
				// this.getPageData();
				// this.submitData = [];
				// this.filterData.refNosStr = "";
				// this.filterData.saleOrderCodeStr = "";
				// this.filterData.warehouseOrderCodeStr = "";
			},
			//下载pdf
			// downLoadPdf(){
			// 	this.$nextTick(()=>{
			// 		exportPdfByShippingMark(this.priterData,"ShippingMark_"+this.row.packingNo);
			// 	});
			// },
			// 新建
			// add() {
			// 	this.submitData.push({
			// 		saleOrderCode: '', //
			// 		warehouseOrderCode: '' ,//
			// 		refNo:''
			// 	});
			// },
			// //删除
			// del(event, row, index) {
			// 	this.submitData.splice(index, 1);
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			//刷新发货仓库
			submitAction() {
				let parm = {
					ids:this.ids,
					warehouseCode: this.filterData.whNo
				};

				this.postData(this.$urlConfig.WhThirdOrderUpdateWh, parm);
			},
			//请求分页数据
			// getPageData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhPrinterShippingMark + '/' + this.filterData.id, {})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.rows) {
			// 				this.priterData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error('数据有误!');
			// 			this.loading_load = false;
			// 		});
			// },
			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {

						// this.getPageData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						this.$emit("actionSuccess");
						this.dialogFile = false;
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_ec_order_status'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {

			// 				// this.selectOption.wh_third_platform = data.data['wh_third_platform'];
			// 				this.selectOption.wh_ec_order_status = data.data['wh_ec_order_status'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.filter-name {
	display: inline-block;
	vertical-align: middle;
	width: 150px;
	text-align: right;
	padding: 0 10px;
}
</style>
